:root {
  /* Colors: */
  --unnamed-color-17a1ba: #17a1ba;
  --unnamed-color-00667d: #00667d;
  --unnamed-color-00000000: #00000000;
  --unnamed-color-ffffff: #ffffff;
  --unnamed-color-cc5d75: #cc5d75;
  --unnamed-color-273565: #273565;
  --unnamed-color-942c70: #942c70;
  --unnamed-color-dc516a: #dc516a;
  --unnamed-color-000000: #000000;

  /* Font/text values */
  --unnamed-font-family-heebo: Heebo;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-300: 300px;
  --unnamed-font-weight-medium: medium;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-16: 16px;
  --unnamed-font-size-18: 18px;
  --unnamed-font-size-20: 20px;
  --unnamed-font-size-36: 36px;
  --unnamed-font-size-50: 50px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-character-spacing--0-18: -0.18px;
  --unnamed-character-spacing--0-36: -0.36px;
  --unnamed-character-spacing--0-2: -0.2px;
  --unnamed-character-spacing--0-16: -0.16px;
  --unnamed-line-spacing-17: 17px;
  --unnamed-line-spacing-22: 22px;
  --unnamed-line-spacing-30: 30px;
  --unnamed-line-spacing-39: 39px;
  --unnamed-line-spacing-42: 42px;
}
body {
  background: transparent radial-gradient(closest-side at 50% 50%, #ffffff 0%, #e5eaf1 0%, #ffffff 100%) 0% 0% no-repeat
    padding-box;
}
a {
  text-decoration: none;
  color: inherit;
}
/* Start of Common */
.pointer {
  cursor: pointer;
}
.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}
/* End of Common */

/* Start of Header */

.header-box {
  cursor: pointer;
  margin-top: 22px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20) / 12px
    var(--unnamed-font-family-heebo);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-273565);
  text-align: left;
  font: normal normal normal 20px/12px Heebo;
  letter-spacing: 0px;
  color: #273565;
  opacity: 1;
}

.header-button {
  cursor: pointer;
  width: 267px;
  height: 50px;
  border: 1px solid var(--unnamed-color-ffffff);
  background: #00000085 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 30px;
  opacity: 1;
}

.header-button-text {
  width: 162px;
  height: 29px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-20) / 12px
    var(--unnamed-font-family-heebo);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal normal 20px/12px Heebo;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.header-button-circle {
  width: 21px;
  height: 21px;
  margin-top: 15px;
  margin-right: 21px;
  background: transparent linear-gradient(180deg, var(--unnamed-color-ffffff) 0%, #c8d2e1 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
}
/* End of Header */

.never-alone-title {
  height: 73px;
  margin-top: 60px;
  font: normal normal bold 50px/30px Heebo;
  letter-spacing: 0px;
  color: #05454f;
  opacity: 1;
}

.al-pi-netunim {
  height: 38px;
  text-align: center;
  font: normal normal normal 26px/30px Heebo;
  letter-spacing: 0px;
  color: #313131;
  opacity: 1;
}

.shalav-title {
  margin-top: 9px;
  height: 100px;
  text-align: center;
  font: normal normal normal 36px/39px Heebo;
  letter-spacing: -0.36px;
  color: #17a1ba;
  opacity: 1;
}

.main-text-area-1 {
  margin-bottom: 103px;
  text-align: center;
  font: normal normal normal 20px/24px Heebo;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

/* Start of ShlavMenopause */

.shlav-menopause-circle {
  width: 95px;
  height: 94px;
  background: transparent linear-gradient(180deg, #ffffff 0%, #cbd5e3 100%) 0% 0% no-repeat padding-box;
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
}

.shlav-menopause-circle-picked {
  width: 95px;
  height: 95px;
  cursor: pointer;
  background: transparent linear-gradient(180deg, #17a1ba 0%, #05454f 100%) 0% 0% no-repeat padding-box;
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
}

.shlav-tag-title {
  margin-top: 4px;
  text-align: center;
  font: normal normal bold 20px/20px Heebo;
  letter-spacing: 0px;
  color: #273565;
  opacity: 1;
}

.shlav-tag-title-picked {
  margin-top: 4px;
  text-align: center;
  font: normal normal bold 20px/20px Heebo;
  letter-spacing: 0px;
  color: #942c70;
  opacity: 1;
}

.shlav-tag-content {
  text-align: center;
  font: normal normal bold 17px/20px Heebo;
  letter-spacing: 0px;
  color: #273565;
  opacity: 1;
}

.dash-line {
  z-index: -1;
  width: 496px;
  height: 0px;
  position: absolute;
  margin-top: 47px;
  border: 2px dashed #ced1d7;
  opacity: 1;
}

/* End of ShlavMenopause */

.main-title-1 {
  text-align: center;
  font: normal normal bold 50px/30px Heebo;
  letter-spacing: 0px;
  color: #05454f;
  opacity: 1;
}

.main-title-2 {
  width: 85%;
  text-align: center;
  font: normal normal normal 26px/30px Heebo;
  letter-spacing: 0px;
  color: #313131;
  opacity: 1;
}

.lottie-wrapper {
  width: 321px;
  height: 321px;
  opacity: 1;
}

.disclamer-1 {
  text-align: right;
  font: normal normal normal 18px/25px Heebo;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.disclamer-checkbox {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
}

Link {
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: right;
  text-decoration: underline;
  font: normal normal normal 18px/25px Heebo;
  letter-spacing: 0px;
  color: #17a1ba;
  opacity: 1;
}

.boi-natchil-button {
  width: 258px;
  height: 61px;
  background: #00667d 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 31px;
  opacity: 1;
}

.boi-natchil-button-text {
  text-align: center;
  font: normal normal normal 30px/30px Heebo;
  letter-spacing: -0.3px;
  color: #ffffff;
  opacity: 1;
}

.disclamer-error-1 {
  text-align: center;
  font: normal normal bold 17px/15px Heebo;
  letter-spacing: 0px;
  color: #dc516a;
  opacity: 1;
}

.disclamer-error-2 {
  text-align: right;
  font: normal normal bold 12px/15px Heebo;
  letter-spacing: 0px;
  color: #dc516a;
  opacity: 1;
}

.progress-bar {
  height: 0px;
  border: 5px solid #d7d9de;
  opacity: 1;
  border-radius: 99px 0px 0px 99px;
}

.progress-bar-filled {
  height: 0px;
  border: 5px solid #273565;
  opacity: 1;
  border-radius: 99px;
}

.shalom-name {
  text-align: center;
  font: normal normal bold 36px/39px Heebo;
  letter-spacing: -0.36px;
  color: #05454f;
  opacity: 1;
}

.shalom-content {
  text-align: center;
  font: normal normal normal 20px/22px Heebo;
  letter-spacing: -0.2px;
  color: #000000;
  opacity: 1;
}

.foot-note-content {
  text-align: center;
  font: normal normal bold 20px/22px Heebo;
  color: #942c70;
  opacity: 1;
}

.footer {
  height: 176px;
  background: #13457f 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
}
.footer-box {
  margin-top: 75px;
  text-align: right;
  font: normal normal normal 18px/28px Heebo;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.input-def {
  text-align: right;
  font: normal normal normal 20px/22px Heebo;
  letter-spacing: -0.2px;
  color: #000000;
}

.nab-button {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;
}
.nab-button-text {
  text-align: center;
  font: normal normal normal 30px/30px Heebo;
  letter-spacing: -0.3px;
  color: #273565;
  opacity: 1;
}

.choice-button {
  box-shadow: #000000;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  place-items: center;
}
.choice-button-text {
  text-align: center;
  font: normal normal normal 17px/21px Heebo;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.choice-side-note {
  text-align: center;
  font: normal normal 300 14px/17px Heebo;
  letter-spacing: -0.16px;
  color: #000000;
  opacity: 1;
}

.forward-button {
  text-align: center;
  font: normal normal normal 35px/35px Heebo;
  letter-spacing: -0.3px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 12px;
  margin-left: 22px;
}
.forward-button:hover {
  color: white;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.slide-in-left {
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

.slide-in-right {
  animation: 0.5s ease-out 0s 1 slideInFromRight;
}

.slide-in-bottom {
  animation: 0.5s ease-out 0s 1 slideInFromBottom;
}

.white-circle {
  border: #000000;
  border-width: 2px;
  border-radius: 50%;
  height: 111px;
  width: 111px;
  background-color: white;
  display: inline-block;
}
